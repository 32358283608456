import { ActionReducerMap } from '@ngrx/store';
import { walletReducer, WalletState } from './wallet.reducer';
import { userReducer, UserState } from './user.reducer';

export interface CoreState {
    wallet: WalletState;
    user: UserState
}

export const reducers: ActionReducerMap<CoreState> = {
    wallet: walletReducer,
    user: userReducer
};

export const selectCoreState = (state: CoreState) => state;
