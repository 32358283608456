import * as tslib_1 from "tslib";
import * as i0 from "@angular/core";
var FileCacheService = /** @class */ (function () {
    function FileCacheService() {
        this.files = [];
    }
    FileCacheService.prototype.getFiles$ = function () {
        return tslib_1.__spread(this.files);
        // return this.file$.getValue();
    };
    FileCacheService.prototype.cacheFile = function (data) {
        this.files.push(data);
    };
    FileCacheService.prototype.clearFiles = function () {
        this.files = [];
    };
    FileCacheService.prototype.updateFiles = function (files) {
        this.files = files;
    };
    FileCacheService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FileCacheService_Factory() { return new FileCacheService(); }, token: FileCacheService, providedIn: "root" });
    return FileCacheService;
}());
export { FileCacheService };
