import { MatSnackBar } from '@angular/material/snack-bar';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/snack-bar";
var AlertService = /** @class */ (function () {
    function AlertService(_snackBar) {
        this._snackBar = _snackBar;
        this.durationInSeconds = 3;
    }
    AlertService.prototype.openSnackBar = function (message, action) {
        this._snackBar.open(message, action, {
            duration: this.durationInSeconds * 1000,
        });
    };
    AlertService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AlertService_Factory() { return new AlertService(i0.ɵɵinject(i1.MatSnackBar)); }, token: AlertService, providedIn: "root" });
    return AlertService;
}());
export { AlertService };
