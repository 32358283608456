import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Constant } from 'app/shared/constant';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ContactUsService = /** @class */ (function () {
    function ContactUsService(httpClient) {
        this.httpClient = httpClient;
    }
    ContactUsService.prototype.create$ = function (param) {
        var _this = this;
        var url = environment.api.URL + environment.api.version;
        return new Observable(function (observer) {
            _this.httpClient.post("" + url + Constant.API.contactUs, param).subscribe(function (res) {
                observer.next(res);
            }, function (err) {
                observer.error(err);
            });
        });
    };
    ContactUsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ContactUsService_Factory() { return new ContactUsService(i0.ɵɵinject(i1.HttpClient)); }, token: ContactUsService, providedIn: "root" });
    return ContactUsService;
}());
export { ContactUsService };
