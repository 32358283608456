import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { Constant } from '@app/shared/constant';
import { WalletActions } from '@app/store';
import { Store } from '@ngrx/store';
import { AlertService } from './alert.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ngrx/store";
import * as i3 from "../store/actions/wallet.actions";
import * as i4 from "./alert.service";
var WalletService = /** @class */ (function () {
    function WalletService(http, store, walletActions, alertService) {
        this.http = http;
        this.store = store;
        this.walletActions = walletActions;
        this.alertService = alertService;
        this.apiURL = environment.api.URL;
    }
    WalletService.prototype.getWalletInfo$ = function (userId) {
        var _this = this;
        return new Observable(function (observer) {
            var url = "" + _this.apiURL + environment.api.version + Constant.API.users + "/" + userId + "/" + Constant.API.wallet;
            _this.http.get(url).subscribe(function (res) {
                observer.next(res);
            }, function (err) { return observer.error(err); });
        });
    };
    WalletService.prototype.refreshWalletData = function (userId) {
        this.store.dispatch(this.walletActions.getWalletData(userId));
    };
    WalletService.prototype.updateWalletSetting$ = function (userId, params) {
        var _this = this;
        return new Observable(function (observer) {
            _this.http.put(_this.apiURL + environment.api.version + Constant.API.users + '/' + userId, params)
                .subscribe(function (data) {
                observer.next(data);
                _this.alertService.openSnackBar('Wallet settings updated', '');
            }, function (err) {
                _this.alertService.openSnackBar('Failed', '');
                observer.error(err);
            });
        });
    };
    WalletService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WalletService_Factory() { return new WalletService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Store), i0.ɵɵinject(i3.WalletActions), i0.ɵɵinject(i4.AlertService)); }, token: WalletService, providedIn: "root" });
    return WalletService;
}());
export { WalletService };
