import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
var FileDurationService = /** @class */ (function () {
    function FileDurationService() {
    }
    FileDurationService.prototype.getDuration$ = function (file) {
        // https://codepen.io/sharma-sk/pen/bVxyRm?editors=1010
        return new Observable(function (observer) {
            var reader = new FileReader();
            // console.log(file);
            reader.readAsDataURL(file);
            reader.onload = function (event) {
                // console.log(event);
                if (file.type == 'video/mp4' || file.type == 'video/ogg' || file.type == 'video/webm') {
                    var videoElement = document.createElement('video');
                    videoElement.src = event.target.result;
                    var timer = setInterval(function () {
                        if (videoElement.readyState === 4) {
                            observer.next(videoElement.duration);
                            observer.complete();
                            // let getTime = this.secondsToTime(videoElement.duration);
                            // console.log(getTime);
                            clearInterval(timer);
                        }
                    }, 500);
                }
                else if (file.type == 'audio/mp3' || file.type == 'audio/mpeg' || file.type == 'audio/wav' || file.type == 'audio/ogg') {
                    var audioElement = document.createElement('audio');
                    audioElement.src = event.target.result;
                    var timer = setInterval(function () {
                        if (audioElement.readyState === 4) {
                            observer.next(audioElement.duration);
                            observer.complete();
                            // let getTime = this.secondsToTime(audioElement.duration);
                        }
                        clearInterval(timer);
                    }, 500);
                }
                else {
                    observer.next(0);
                    observer.complete();
                }
            };
        });
    };
    FileDurationService.prototype.secondsToTime = function (in_seconds) {
        var time = '';
        in_seconds = parseFloat(in_seconds.toFixed(2));
        var hours = Math.floor(in_seconds / 3600);
        var minutes = Math.floor((in_seconds - hours * 3600) / 60);
        var seconds = in_seconds - hours * 3600 - minutes * 60;
        //seconds = Math.floor( seconds );
        seconds = seconds.toFixed(0);
        if (hours < 10) {
            hours = '0' + hours;
        }
        if (minutes < 10) {
            minutes = '0' + minutes;
        }
        if (seconds < 10) {
            seconds = '0' + seconds;
        }
        time = minutes + ':' + seconds;
        return time;
    };
    FileDurationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FileDurationService_Factory() { return new FileDurationService(); }, token: FileDurationService, providedIn: "root" });
    return FileDurationService;
}());
export { FileDurationService };
