import { ExtraOptions, Routes } from '@angular/router';
import { RedirectingPageComponent } from './components/redirecting-page/redirecting-page.component';
import { FullLayoutComponent } from './container/full-layout/full-layout.component';
var ɵ0 = function () { return import("./pages/home/home.module.ngfactory").then(function (m) { return m.HomeModuleNgFactory; }); }, ɵ1 = {
    type: 'home',
    title: 'Home',
}, ɵ2 = function () { return import("./modules/transcript/transcript.module.ngfactory").then(function (m) { return m.TranscriptModuleNgFactory; }); }, ɵ3 = {
    title: 'Transcript',
}, ɵ4 = function () { return import("./pages/contact-us/contact-us.module.ngfactory").then(function (m) { return m.ContactUsModuleNgFactory; }); }, ɵ5 = function () { return import("./pages/faq/faq.module.ngfactory").then(function (m) { return m.FaqModuleNgFactory; }); }, ɵ6 = function () { return import("./pages/feature/feature.module.ngfactory").then(function (m) { return m.FeatureModuleNgFactory; }); }, ɵ7 = function () { return import("./pages/industries/industries.module.ngfactory").then(function (m) { return m.IndustriesModuleNgFactory; }); }, ɵ8 = function () { return import("./pages/privacy-policy/privacy-policy.module.ngfactory").then(function (m) { return m.PrivacyPolicyModuleNgFactory; }); }, ɵ9 = function () { return import("./pages/terms-of-service/terms-of-service.module.ngfactory").then(function (m) { return m.TermsOfServiceModuleNgFactory; }); }, ɵ10 = function () { return import("./pages/audio-to-text/audio-to-text.module.ngfactory").then(function (m) { return m.AudioToTextModuleNgFactory; }); }, ɵ11 = function () { return import("./pages/video-to-text/video-to-text.module.ngfactory").then(function (m) { return m.VideoToTextModuleNgFactory; }); }, ɵ12 = function () { return import("./modules/checkout/checkout.module.ngfactory").then(function (m) { return m.CheckoutModuleNgFactory; }); }, ɵ13 = function () { return import("./modules/dashboard/dashboard.module.ngfactory").then(function (m) { return m.DashboardModuleNgFactory; }); }, ɵ14 = function () { return import("./modules/settings/settings.module.ngfactory").then(function (m) { return m.SettingsModuleNgFactory; }); };
// Import Containers
export var routes = [
    {
        path: '',
        component: FullLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: ɵ0,
                data: ɵ1,
            },
            {
                path: 'transcript',
                loadChildren: ɵ2,
                data: ɵ3,
            },
            // {
            //   path: 'transactions',
            //   loadChildren: () => import('./modules/transactions/transactions.module').then((m) => m.TransactionsModule),
            //   data: {
            //     title: 'Transactions',
            //   },
            // },
            {
                path: 'contact-us',
                loadChildren: ɵ4
            },
            {
                path: 'faq',
                loadChildren: ɵ5
            },
            {
                path: 'features',
                loadChildren: ɵ6
            },
            {
                path: 'industries',
                loadChildren: ɵ7
            },
            {
                path: 'privacy-policy',
                loadChildren: ɵ8
            },
            {
                path: 'terms-of-service',
                loadChildren: ɵ9
            },
            {
                path: 'audio-to-text',
                loadChildren: ɵ10
            },
            {
                path: 'video-to-text',
                loadChildren: ɵ11
            },
            {
                path: 'checkout',
                loadChildren: ɵ12,
            },
            {
                path: 'dashboard',
                loadChildren: ɵ13,
            },
            {
                path: 'settings',
                loadChildren: ɵ14,
            },
            {
                path: 'redirecting',
                component: RedirectingPageComponent,
            },
        ]
    },
];
export var routingConfiguration = {
    paramsInheritanceStrategy: 'always',
};
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14 };
