import * as tslib_1 from "tslib";
import { EventEmitter, ElementRef, OnDestroy, ChangeDetectorRef } from '@angular/core';
import Swal from 'sweetalert2';
var StripeJsComponent = /** @class */ (function () {
    function StripeJsComponent(cd) {
        this.cd = cd;
        this.onSaveCard = new EventEmitter();
        this.token = new EventEmitter();
        this.mode = 'pay';
        this.amount = 0;
        this.cardHandler = this.onChange.bind(this);
        this.savedCardSelected = '';
        this.saveCard = true;
        this.isValid = false;
        this.address = {};
        // pk_test_DnemtUcQFgmADMUps1luv03900fphHW38U
        // pk_live_ZLp10P4YndHd5utZEn3QwgAc00gCTdg4b5
        // var stripe = stripe("pk_test_DnemtUcQFgmADMUps1luv03900fphHW38U");
        // var elements = stripe.elements();
    }
    StripeJsComponent.prototype.ngAfterViewInit = function () {
        this.card = elements.create("card");
        this.card.mount(this.cardInfo.nativeElement);
        this.card.addEventListener("change", this.cardHandler);
    };
    StripeJsComponent.prototype.onChange = function (_a) {
        var error = _a.error;
        if (error) {
            this.error = error.message;
        }
        else {
            this.error = null;
        }
        this.cd.detectChanges();
    };
    StripeJsComponent.prototype.onSubmit = function (form) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, token, error;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, stripe.createToken(this.card, this.address)];
                    case 1:
                        _a = _b.sent(), token = _a.token, error = _a.error;
                        if (!error) {
                            this.emitToken(token);
                            this.card.clear();
                        }
                        else {
                            Swal.fire('Error', error.message, 'error');
                            // throw error('Something went wrong.');
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    StripeJsComponent.prototype.emitToken = function (token) {
        this.address = {};
        this.token.emit({ id: token.id, address: this.address });
    };
    StripeJsComponent.prototype.ngOnDestroy = function () {
        this.card.destroy();
    };
    StripeJsComponent.prototype.changeSaveCard = function (e) {
        this.saveCard = e;
        this.onSaveCard.emit(e);
    };
    StripeJsComponent.prototype.validate = function () {
        var _this = this;
        this.isValid = Object.keys(this.address).every(function (el) { return _this.address[el]; });
    };
    return StripeJsComponent;
}());
export { StripeJsComponent };
