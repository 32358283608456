import { ElementRef, ChangeDetectorRef, EventEmitter, } from "@angular/core";
import { PaymentsService } from "@app/services/payments.service";
import { CardsService } from '@app/services/cards.service';
import { WalletService } from '@app/services/wallet.service';
import { Store } from '@ngrx/store';
var PaymentComponent = /** @class */ (function () {
    function PaymentComponent(cd, cardsService, paymentsService, walletService, store) {
        this.cd = cd;
        this.cardsService = cardsService;
        this.paymentsService = paymentsService;
        this.walletService = walletService;
        this.store = store;
        this.saveCard = true;
        this.selectedCardId = '';
        this.savedCardSelected = true;
        this.cardHandler = this.onChange.bind(this);
        this.cancel = new EventEmitter();
        this.success = new EventEmitter();
        this.amount = 0;
        this.observeUserState();
        this.getCards();
    }
    PaymentComponent.prototype.observeUserState = function () {
        var _this = this;
        this.store.select(function (s) { return s.user; }).subscribe(function (res) {
            _this.user = res;
        });
    };
    PaymentComponent.prototype.getCards = function () {
        var _this = this;
        this.cardsService.getCards$().subscribe(function (res) {
            _this.savedCardSelected = res.savedCards && res.savedCards.length ? true : false;
            _this.defaultCard = res.defaultCard ? res.defaultCard : '';
            _this.selectedCardId = _this.defaultCard;
            _this.savedCards = res.savedCards ? res.savedCards : [];
        });
    };
    PaymentComponent.prototype.createStripeElement = function () {
        this.card = elements.create("card");
        this.card.mount(this.cardInfo.nativeElement);
        this.card.addEventListener("change", this.cardHandler);
    };
    PaymentComponent.prototype.paymentOptionChange = function (savedCardSelected, cardId) {
        this.savedCardSelected = savedCardSelected;
        this.selectedCardId = cardId;
        if (this.savedCardSelected)
            this.saveCard = false;
    };
    PaymentComponent.prototype.onChange = function (_a) {
        var error = _a.error;
        if (error) {
            this.error = error.message;
        }
        else {
            this.error = null;
        }
        this.cd.detectChanges();
    };
    PaymentComponent.prototype.onSuccess = function () {
        var _this = this;
        this.savedCardSelected = true;
        setTimeout(function () {
            _this.success.emit();
        }, 300);
    };
    PaymentComponent.prototype.onCancel = function () {
        this.cancel.emit();
    };
    PaymentComponent.prototype.createPayment = function (paymentToken, newCard) {
        var _this = this;
        this.paymentsService
            .createPayment$({ paymentToken: paymentToken.id, amount: this.amount, saveCard: this.saveCard, newCard: newCard })
            .subscribe(function (res) {
            _this.walletService.refreshWalletData(_this.user.id);
            _this.onSuccess();
        });
    };
    PaymentComponent.prototype.changeSaveCard = function (e) {
        this.saveCard = e;
    };
    return PaymentComponent;
}());
export { PaymentComponent };
