import { HttpClient, HttpBackend } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Constant } from '../shared/constant';
import { AlertService } from '@app/services/alert.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./alert.service";
var LoginService = /** @class */ (function () {
    function LoginService(httpClient, handler, alertService) {
        this.httpClient = httpClient;
        this.handler = handler;
        this.alertService = alertService;
        this.bypassedClient = new HttpClient(handler);
    }
    LoginService.prototype.get$ = function (params) {
        var _this = this;
        var url = environment.api.URL + environment.api.version;
        return new Observable(function (observer) {
            _this.bypassedClient.post(url + Constant.API.authenticate, params, { observe: 'response' }).subscribe(function (res) {
                _this.alertService.openSnackBar("Hola! " + res.body.user.name, '');
                observer.next(res);
            }, function (err) {
                _this.alertService.openSnackBar('OOPS! invalid username or password.', '');
                observer.error(err);
            });
        });
    };
    LoginService.prototype.create$ = function (params) {
        var _this = this;
        var url = environment.api.URL + environment.api.version;
        return new Observable(function (observer) {
            _this.bypassedClient.post(url + Constant.API.users, params, { observe: 'response' }).subscribe(function (res) {
                observer.next(res);
            }, function (err) {
                _this.alertService.openSnackBar(err.error.message, '');
                observer.error(err);
            });
        });
    };
    LoginService.prototype.forgotPassword$ = function (email) {
        var _this = this;
        var url = environment.api.URL + environment.api.version;
        return new Observable(function (observer) {
            _this.bypassedClient.get("" + url + Constant.API.forgotPassword + "?email=" + email, { observe: 'response' }).subscribe(function (res) {
                console.log(res);
                // this.alertService.openSnackBar(`Hola! ${res.body.user.name}`, '');
                observer.next(res);
            }, function (err) {
                _this.alertService.openSnackBar('OOPS! email address doesnt exist', '');
                observer.error(err);
            });
        });
    };
    LoginService.prototype.changePassword$ = function (postData, userId) {
        var _this = this;
        var url = environment.api.URL + environment.api.version;
        return new Observable(function (observer) {
            _this.httpClient.post("" + url + Constant.API.users + "/" + userId + "/changepassword", postData, { observe: 'response' }).subscribe(function (res) {
                _this.alertService.openSnackBar("Password changed successfully", '');
                observer.next(res);
            }, function (err) {
                _this.alertService.openSnackBar(err.error.message, '');
                observer.error(err);
            });
        });
    };
    LoginService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginService_Factory() { return new LoginService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i1.HttpBackend), i0.ɵɵinject(i2.AlertService)); }, token: LoginService, providedIn: "root" });
    return LoginService;
}());
export { LoginService };
