import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from "@ngrx/effects";
import { WalletActions } from "app/store/actions";
import { map, switchMap } from "rxjs/operators";
import { WalletService } from '@app/services/wallet.service';
var WalletEffects = /** @class */ (function () {
    function WalletEffects(actions$, walletService, walletActions) {
        var _this = this;
        this.actions$ = actions$;
        this.walletService = walletService;
        this.walletActions = walletActions;
        this.getWalletData$ = this.actions$
            .pipe(ofType(WalletActions.GET_Wallet_DATA))
            .pipe(map(function (action) { return action.payload; }), switchMap(function (payload) { return _this.walletService.getWalletInfo$(payload); }), map(function (data) { return _this.walletActions.getWalletDataSuccess(data); }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], WalletEffects.prototype, "getWalletData$", void 0);
    return WalletEffects;
}());
export { WalletEffects };
